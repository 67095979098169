import { useNuxtApp } from '#app'
import { useAPI, useSharedPromise } from '#imports'
import type { Availability, ReceivingDateForecast } from '@/modules/nuxt-api/models/Product'
import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb'

export type Paginator = {
  page: number
  perPage: number
  totalItems?: number | undefined
}

export type ProductListAvailabilityAndForecast = {
  availability: [number, Availability][] | undefined
  forecasts: [number, ReceivingDateForecast][] | undefined
}

type State = {
  availability: Record<number, Availability>
  packagesMap: Record<number, Product.AsObject['pb_package']>
  pricesMap: Record<number, Product.AsObject['pricing']>
  receivingForecasts: Record<number, ReceivingDateForecast>
}

export function useProducts () {
  const nuxtApp = useNuxtApp()
  const state = useHydrationStore<State>('products-store', {
    availability: {},
    packagesMap: {},
    pricesMap: {},

    receivingForecasts: {}
  })

  async function getProducts (
    path: string,
    paginator: Paginator,
    sorting?: string,
    query?: string
  ) {
    if (!path && !query) {
      return {
        products: [],
        sorting: ''
      }
    }
    const data = await nuxtApp.runWithContext(() => useSharedPromise(`products-${path}`, async () => {
      const api = useAPI()
      const { getProducts } = api.product()

      return await getProducts(
        path,
        paginator,
        sorting,
        query
      )
    }))

    return {
      products: data?.products,
      paginator: { ...paginator, ...data?.pagination },
      sorting: data?.sorting
    }
  }

  async function getProductCountForSection (path: string) {
    const api = useAPI()
    const { getProductCountForSection } = api.product()
    return await getProductCountForSection(path)
  }

  async function getProductAvailability (ids?: number[]) {
    if (!ids) { return ({}) }

    const idsList: number[] = []
    const availabilityResData: Record<number, Availability> = {}

    ids.forEach((id) => {
      if (id in state.value.availability) {
        availabilityResData[id] = state.value.availability[id]
      } else {
        idsList.push(id)
      }
    })

    if (idsList.length !== 0) {
      const { getProductOperationalData } = useAPI().product()
      const availabilityData = await getProductOperationalData(idsList).catch(() => undefined)

      if (availabilityData && availabilityData.availabilitiesMap.length !== 0) {
        availabilityData.availabilitiesMap.forEach(([id, value]) => {
          state.value.availability[id] = value
          availabilityResData[id] = value
        })
      }

      if (availabilityData && availabilityData.packagesMap.length !== 0) {
        availabilityData.packagesMap.forEach(([id, value]) => {
          state.value.packagesMap[id] = value
        })
      }

      if (availabilityData && availabilityData.pricesMap.length !== 0) {
        availabilityData.pricesMap.forEach(([id, value]) => {
          state.value.pricesMap[id] = value
        })
      }
    }

    return availabilityResData
  }

  async function getProductReceivingForecasts (ids?: number[]) {
    if (!ids) { return ({}) }

    const idsList: number[] = []
    const forecastsResData: Record<number, ReceivingDateForecast> = {}

    ids.forEach((id) => {
      if (id in state.value.receivingForecasts) {
        forecastsResData[id] = state.value.receivingForecasts[id]
      } else {
        idsList.push(id)
      }
    })

    if (idsList.length !== 0) {
      const { getProductReceivingForecast } = useAPI().product()
      const forecastsAPIData = await getProductReceivingForecast(idsList)

      if (forecastsAPIData && forecastsAPIData.length !== 0) {
        forecastsAPIData.forEach(([id, value]) => {
          state.value.receivingForecasts[id] = value
          forecastsResData[id] = value
        })
      }
    }

    return forecastsResData
  }

  return {
    getProducts,
    getProductCountForSection,
    getProductAvailability,
    getProductReceivingForecasts,

    availability: toRef(state.value, 'availability'),
    packagesMap: toRef(state.value, 'packagesMap'),
    pricesMap: toRef(state.value, 'pricesMap'),
    receivingForecasts: toRef(state.value, 'receivingForecasts')
  }
}
